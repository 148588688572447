@media screen and (min-width: 600px) {
  .jumbotron-manuals {
    position: relative;
    width: 100%;
    height: 19.25rem;
    padding: 0.438rem 0;
    object-fit: contain;
  }
}

@media screen and (max-width: 600px) {
  .jumbotron-manuals {
    text-align: center !important;
  }
}
.jumbotron-manuals {
  border-radius: unset;
}

/* TODO: Move in repository */
.download-manual-jumbotron {
  background-image: unset;
  background: black;
  @media screen and (max-width: 768px) {
    height: 12.813rem;
  }
}

.jumbotron-devoluciones {
  background-image: unset;
  background: black;
}
