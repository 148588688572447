/* Fuentes */
@import 'mixins.scss';
@import '../assets/scss/paper-kit/variables';

h1,
.header-1 {
  @include font-style(3.125rem, $dark-brown-color, normal, $font-family-secondary);
  @include font-mobile-style(2.25rem, normal, 1.2);
  margin-top: 0px !important;
  font-weight: 500;
  line-height: 1.2
}

h2,
.header-2 {
  @include font-style(2.5rem, $dark-brown-color, normal, $font-family-secondary);
  @include font-mobile-style(1.875rem, normal, 1.2);
  margin-top: 0px !important;
  line-height: 1.2;
  font-weight: 500;
}

h3,
.header-3 {
  @include font-style(2.125rem, $dark-brown-color, normal, $font-family-secondary);
  @include font-mobile-style(1.625rem, normal, 1.2);
  margin-top: 0px !important;
  font-weight: 500;
  line-height: 1.2;
}

h4,
.header-4 {
  @include font-style(1.875rem, $dark-brown-color, normal, $font-family-secondary);
  @include font-mobile-style(1.5rem, normal,1.2);
  margin-top: 0px !important;
  line-height: 1.2;
  font-weight: 500;
}

.subtitle-1 {
  @include font-style(1.75rem, $dark-brown-color, 0.56px, $font-family-primary);
  @include font-mobile-style(1.375rem, 0.44px);
  font-weight: bold;
  line-height: 1.5;
}

.subtitle-2 {
  @include font-style(1.5rem, $dark-brown-color, 0.48px, $font-family-primary);
  @include font-mobile-style(1.25rem, 0.4px);
  font-weight: bold;
  line-height: 1.5;
}

.subtitle-3 {
  @include font-style(1.125rem, $dark-brown-color, 0.4px, $font-family-primary);
  @include font-mobile-style(1rem, 0.32px);
  font-weight: bold;
  line-height: 1.5;
}

.subtitle-4 {
  @include font-style(1.125rem, #B8A88F, 0.3px, $font-family-primary);
  @include font-mobile-style(0.938rem, 1.02px);
  font-weight: bold;
}

.subtitle-5 {
  @include font-style(2rem, $dark-brown-color, 1.42px, $font-family-primary);
  @include font-mobile-style(1.375rem, 1.02px);
  font-weight: 600;
}

p,
.paragraph-1 {
  @include font-style(1rem, $dark-brown-color, 0.32px, $font-family-primary);
  @include font-mobile-style(0.875rem, 0.28px);
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0px
}

.paragraph-2 {
  @include font-style-without-color(0.875rem, 0.28px, $font-family-primary);
  @include font-mobile-style(0.875rem, 0.28px);
  font-weight: normal;
  line-height: 1.5;
}

.paragraph-3 {
  @include font-style-without-color(0.75rem, 0.24px, $font-family-primary);
  font-weight: normal;
  line-height: 1.5;
}

.paragraph-4 {
  @include font-style(0.625rem, $dark-brown-color, 0.2px, $font-family-primary);
  font-weight: normal;
  line-height: 1.5;
}

button,
.btn {
  &:not(.fa, .nc-icon) {
    @include font-style-btn(0.875rem, 0.84px, $font-family-primary);
    font-weight: 600;
  }
}
