$breakpoints: (
  '': 0,
  'sm': calc(576px - 1px),
  'md': calc(768px - 1px),
  'lg': calc(992px - 1px),
  'xl': calc(1200px - 1px),
  'xxl': calc(1440px - 1px)
);

$spacings: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '2-5': 0.75rem,
  '3': 1rem,
  '3-5': 1.25rem,
  '4': 1.5rem,
  '4-5': 2.25rem,
  '5': 3rem,
  '6': 4rem
);

@mixin generate-styles($spacing-name, $spacing-value, $breakpoint-name: '') {
  $prefix: if($breakpoint-name == '', '', '#{$breakpoint-name}-');
  @each $prop, $abbrev in (margin: m, padding: p) {
    .#{$abbrev}-#{$prefix}#{$spacing-name} {
      #{$prop}: $spacing-value !important;
    }

    .#{$abbrev}y-#{$prefix}#{$spacing-name},
    .#{$abbrev}t-#{$prefix}#{$spacing-name} {
      #{$prop}-top: $spacing-value !important;
    }

    .#{$abbrev}y-#{$prefix}#{$spacing-name},
    .#{$abbrev}b-#{$prefix}#{$spacing-name} {
      #{$prop}-bottom: $spacing-value !important;
    }

    .#{$abbrev}x-#{$prefix}#{$spacing-name},
    .#{$abbrev}l-#{$prefix}#{$spacing-name} {
      #{$prop}-left: $spacing-value !important;
    }

    .#{$abbrev}x-#{$prefix}#{$spacing-name},
    .#{$abbrev}r-#{$prefix}#{$spacing-name} {
      #{$prop}-right: $spacing-value !important;
    }
  }

  /* Margins --------------------------------
  .m-#{$prefix}#{$spacing-name} {
    margin: $spacing-value !important;
  }

  .mx-#{$prefix}#{$spacing-name} {
    margin-left: $spacing-value !important;
    margin-right: $spacing-value !important;
  }

  .my-#{$prefix}#{$spacing-name} {
    margin-top: $spacing-value !important;
    margin-bottom: $spacing-value !important;
  }

  .mt-#{$prefix}#{$spacing-name} {
    margin-top: $spacing-value !important;
  }

  .mb-#{$prefix}#{$spacing-name} {
    margin-bottom: $spacing-value !important;
  }

  .ml-#{$prefix}#{$spacing-name} {
    margin-left: $spacing-value !important;
  }

  .mr-#{$prefix}#{$spacing-name} {
    margin-right: $spacing-value !important;
  }*/
}

@each $breakpoint-name, $breakpoint-value in $breakpoints {
  @if $breakpoint-name == '' {
    @each $spacing-name, $spacing-value in $spacings {
      @include generate-styles($spacing-name, $spacing-value);
    }
  } @else {
    @media (min-width: $breakpoint-value) {
      @each $spacing-name, $spacing-value in $spacings {
        @include generate-styles($spacing-name, $spacing-value, $breakpoint-name);
      }
    }
  }
}
