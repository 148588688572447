.dropdown {
  .dropdown-content,
  .dropdown-content-temp {
    visibility: hidden;
    opacity: 0;
    transition: 0s;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    padding-bottom: 20px;
    -webkit-box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
    box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
  }
  &:not(.no-hover):hover {
    .dropdown-content {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.4s;
    }
    .nav-link,
    .nav-link a {
      letter-spacing: 0.32px;
      //text-decoration: underline;
      //font-weight: 700;
      cursor: pointer;
      &::before {
        opacity: 0;
        z-index: -1;
        @media screen and (min-width: 992px) {
          opacity: 1;
        }
      }
    }
    .nav-link {
      a {
        border-bottom: 1px solid var(--dark-brown-color);
      }
    }
  }
  // &:active {
  //   .nav-link {
  //     //font-weight: bold;
  //     //text-decoration: underline;
  //   }
  // }
  .dropdown-item:hover {
    background-color: #ffffff;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.nav-bar-menu {
  margin: 0px !important;
  padding: 0.75rem !important;
  @media screen and (max-width: 991px) {
    padding: 15px 8% !important;
    width: 100%;
    font-weight: normal !important;
    font-size: 1.125rem !important;
    letter-spacing: 1.02px !important;
  }
}

/* Menu1 */
.navbar-title {
  font-family: "Work Sans", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  color: var(--dark-brown-color);
  letter-spacing: 0.15px;
  font-weight: 600;
  text-transform: uppercase;
  @media screen and (max-width: 991px) {
    font-weight: normal;
    font-size: 1.125rem;
    letter-spacing: 1.02px;
  }
}

/* Menu2 */
.navbar-subtitle {
  font-family: "Work Sans", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  color: var(--dark-brown-color);
  letter-spacing: 0.32px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}

/* Menu3 */
.navbar-item {
  color: var(--dark-brown-color);;
  font-family: "Work Sans", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem !important;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  font-weight: 400;
  @media screen and (min-width: 991px) {
    letter-spacing: 0.32px;
    font-size: 0.688rem;
    font-weight: 400;
  }
}

/* link debajo de la imagen en version Desktop */
.ver-todo-image {
  font-family: "Work Sans", "Helvetica", Arial, sans-serif;
  font-size: 0.9rem;
  color: black;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-decoration: underline !important;
}

.unique-sub-menu {
  margin-right: 0;
  margin-left: 0;
}

.menu-link {
  text-decoration: underline !important;
  font-size: 0.9rem;
}

.m-auto {
  margin: auto;
}


.mobile-navbar-item, .mobile-navbar-parent {
  font-family: "Work Sans", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem !important;
  color: #373430 !important;
  letter-spacing: 0.51px;
  font-weight: 400;
  text-align: left;
  line-height: 1.026rem;
  text-transform: uppercase !important;
}

.navbar-current {
  font-family: "Work Sans", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem !important;
  color: #373430  ;
  letter-spacing: 0.51px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  text-align: left !important;
  line-height: 1.026rem !important;
}

.nav-notification-bubble {
  position: absolute;
  right: 0px;
  top: 50%;
  padding: 0.4em 0.6em;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    right: 10px;
  }
}
