$urlPath: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/images/assets';
$font-color: #1d1c1b !default;
$fill-font-color: rgba(255, 255, 255, 0.8);
$font-family-primary: 'Work Sans', 'Helvetica', Arial, sans-serif;
$font-family-secondary: 'Playfair Display', 'Helvetica', Arial, sans-serif;

$primary-color: #000000 !default;
$success-color: #003a63 !default;
$success-color-2: #100f0f !default; //TODO - Add the color in success after all the changes
$muted-color: #a8afb9 !default;
$white-color: #ffffff !default;
$warning-color: #e51414 !default;
$danger-color: #e51414 !default;
$grey-color: #a3a3a3 !default;
$primary-gold-color: #b8a88f !default;
$light-grayish-color: #f6f6f6 !default;

$azul1-color: #3b5877 !default;
$azul2-color: #788497 !default;
$azul3-color: #d4d8dc !default;

$gris1-color: #f2f2f2 !default;
$gris2-color: rgba(55, 52, 48, 0.28) !default;
$gris3-color: rgba(55, 52, 48, 0.4) !default;
$gris4-color: rgba(55, 52, 48, 0.7) !default;
$gris5-color: #373430 !default;
$gris6-color: #100f0f !default;

$gris7-color: #d8d8d8 !default;
$gris8-color: #424f5b !default;
$gris9-color: #85878b !default;
$gris10-color: #373430 !default;

$gold1-color: #f4f1ec !default;
$gold2-color: #dfd7c9 !default;
$gold3-color: #b8a88f !default;

$red1-color: #dc3545 !default;
$red2-color: #b50011 !default;

$pre-sale-color: #2b9206 !default;
$pre-sale-1-color: #caf3bb !default;

$orange-color: #ff6b00 !default;
$yellow-color: #ffc700 !default;

$footer-bottom-bar-color: #100f0f !default;
$filter-disabled-text-color: #003a63 !default;

$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;

$white-color: #ffffff !default;
$white-bg: #ffffff !default;
$white-states-color: rgba($white-color, 0.8);

$smoke-bg: #f5f5f5 !default;
$pale-bg: #fffcf5 !default;
$medium-pale-bg: #f1eae0 !default;

$table-line-color: #ccc !default;

$black-bg: rgba(30, 30, 30, 0.97) !default;

$black-color: #333333 !default;
$black-hr: #444444 !default;
$black2-color: #1d1d1b !default;

$grayish-orange-color: #d5ccc4 !default;
$light-gray: #e3e3e3 !default;
$medium-gray: #dddddd !default;
$dark-gray: #9a9a9a !default;
$dark-grayish-color: #3a3935 !default;
$border-imput-gray: rgba(55, 52, 48, 0.4) !default;

$placeholder-gray: rgba(210, 210, 210, 1) !default;

$gray-input-bg: #fffcf5 !default;
$danger-input-bg: #ffc0a4 !default;
$success-input-bg: #abf3cb !default;
$other-medium-gray: #a49e93 !default;
$transparent-bg: transparent !default;

$default-color: #66615b !default;
$default-bg: #66615b !default;
$default-states-color: #403d39 !default;

$cesta-color: rgb(175, 175, 175) !default;
$cesta-states-color: rgb(207, 207, 207) !default;

$primary-states-color: darken($primary-color, 10%) !default;

$success-states-color: darken($success-color, 10%) !default;

$info-color: #000000 !default;
$info-states-color: darken($info-color, 10%) !default;

$warning-states-color: darken($warning-color, 10%) !default;

$danger-color: #e83b46 !default;
$danger-states-color: darken($danger-color, 8%) !default;

$link-disabled-color: #666666 !default;

$purple-color: #c178c1 !default;
$purple-states-color: darken($purple-color, 8%) !default;

$brown-color: #dcb285 !default;
$brown-states-color: darken($brown-color, 8%) !default;
$dark-brown-color: #373430 !default;
$beige-color: #b8a98f !default;

/*      light colors - used for select dropdown         */

$light-blue: rgba($primary-color, 0.2);
$light-azure: rgba($info-color, 0.2);
$light-green: rgba($success-color, 0.2);
$light-orange: rgba($warning-color, 0.2);
$light-red: rgba($danger-color, 0.2);

$padding-base-vertical: 7px !default;
$padding-base-horizontal: 18px !default;

$padding-round-vertical: 9px !default;
$padding-round-horizontal: 18px !default;

$padding-simple-vertical: 10px !default;
$padding-simple-horizontal: 18px !default;

$padding-large-vertical: 11px !default;
$padding-large-horizontal: 30px !default;

$padding-small-vertical: 4px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 2px !default;
$padding-xs-horizontal: 5px !default;

$padding-label-vertical: 2px !default;
$padding-label-horizontal: 12px !default;

$padding-dropdown-vertical: 10px !default;
$padding-dropdown-horizontal: 15px !default;

$margin-large-vertical: 30px !default;
$margin-base-vertical: 15px !default;

$border-radius-none: 0px !default;
$border-radius-btn-small: 26px !default;
$border-radius-btn-base: 20px !default;
$border-radius-btn-large: 50px !default;

$margin-bottom: 0 0 10px 0 !default;
$border-radius-small: 3px !default;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-x-large: 8px !default;
$border-radius-extreme: 0px !default;

$border-radius-none-top: $border-radius-none $border-radius-none 0 0 !default;
$border-radius-none-bottom: 0 0 $border-radius-none $border-radius-none !default;

$border-radius-small-top: $border-radius-small $border-radius-small 0 0 !default;
$border-radius-small-bottom: 0 0 $border-radius-small $border-radius-small !default;

$border-radius-large-top: $border-radius-large $border-radius-large 0 0 !default;
$border-radius-large-bottom: 0 0 $border-radius-large $border-radius-large !default;

$btn-round-radius: 30px !default;

$height-base: 44px !default;

$font-size-base: 12px !default;
$font-size-small: 11px !default;
$font-size-medium: 14px !default;
$font-size-large: 16px !default;
$font-size-large-navbar: 18px !default;

$font-size-h1: 3.563em !default;
$font-size-h2: 2.5em !default;
$font-size-h3: 2.25em !default;
$font-size-h4: 1.875em !default;
$font-size-h5: 1.35em !default;
$font-size-h6: 0.9em !default;
$font-paragraph: 15px !default;
$font-size-navbar: 14px !default;

$font-size-info-horizontal: 2.5em !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 500 !default;
$font-weight-bold: 600 !default;

$line-height-small: 20px !default;
$line-height-general: 1.5em !default;
$line-height: 36px !default;
$line-height-lg: 54px !default;
$line-height-link-footer: 1.5 !default;
$line-height-link-footer-mobile: 1.5 !default;
$line-height-title-footer: 60px !default;
$line-height-title-footer-mobile: 30px !default;

$border-radius-top: 10px 10px 0 0 !default;
$border-radius-bottom: 0 0 10px 10px !default;
$border-radius-dropdown: 10px 10px !default;

$padding-input-vertical: 0.563rem;
$padding-input-horizontal: 1rem;
$dropdown-shadow:
  0 2px rgba(17, 16, 15, 0.1),
  0 2px 10px rgba(17, 16, 15, 0.1);

$general-transition-time: 300ms !default;

$slow-transition-time: 300ms !default;
$dropdown-coordinates: 29px -50px !default;

$fast-transition-time: 150ms !default;
$select-coordinates: 50% -40px !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;

$navbar-padding-a: 10px 15px;
$navbar-margin-a: 15px 3px;

$padding-social-a: 10px 5px;

$navbar-margin-a-btn: 15px 3px;
$navbar-margin-a-btn-round: 16px 3px;

$navbar-padding-a-icons: 6px 15px;
$navbar-margin-a-icons: 6px 3px;

$navbar-padding-brand: 20px 15px;
$navbar-margin-brand: 5px 0px;

$navbar-margin-brand-icons: 12px auto;

$navbar-margin-btn: 15px 3px;

$height-icon: 64px !default;
$width-icon: 64px !default;
$padding-icon: 12px !default;
$border-radius-icon: 15px !default;

$size-icon: 64px;
$size-icon-sm: 32px;

$height-icon-sm: 32px;
$width-icon-sm: 32px;
$padding-icon-sm: 4px;
$border-radius-icon-sm: 7px;

$height-icon-message: 40px;
$width-icon-message: 40px;

$height-icon-message-sm: 20px;
$width-icon-message-sm: 20px;

$white-navbar: rgba(#ffffff, 0.96);
$blue-navbar: rgba(#34acdc, 0.98);
$azure-navbar: rgba(#5bcaff, 0.98);
$green-navbar: rgba(#4cd964, 0.98);
$orange-navbar: rgba(#ff9500, 0.98);
$red-navbar: rgba(#ff4c40, 0.98);

$bg-nude: #fffcf5 !default;
$bg-primary: lighten($primary-color, 7%) !default;
$bg-info: lighten($info-color, 7%) !default;
$bg-success: lighten($success-color, 7%) !default;
$bg-warning: lighten($warning-color, 7%) !default;
$bg-danger: lighten($danger-color, 7%) !default;
$bg-brown: lighten($brown-color, 7%) !default;
$bg-purple: lighten($purple-color, 7%) !default;

$preloader-color: #d8d1c9 !default;

$filter-blue: darken($primary-color, 10%);
$filter-azure: darken($info-color, 10%);
$filter-green: darken($success-color, 10%);
$filter-orange: darken($warning-color, 10%);
$filter-red: darken($danger-color, 10%);
$filter-badge-radius: 6rem;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

$social-facebook: #3b5998;
$social-twitter: #55acee;
$social-pinterest: #cc2127;
$social-google: #dd4b39;
$social-linkedin: #0976b4;
$social-dribbble: #ea4c89;
$social-github: #333333;
$social-youtube: #e52d27;
$social-instagram: #125688;
$social-reddit: #ff4500;
$social-tumblr: #35465c;

$background-light-grey: #e8e7e3 !default;
$background-lighter-grey: #f0efeb !default;
$font-background-light-grey: #9c9b99 !default;
$font-hover-background-light-grey: #5e5e5c !default;

$header-background: #b2afab !default;

$filter-primary: darken($primary-color, 10%);
$filter-info: darken($info-color, 10%);
$filter-success: darken($success-color, 10%);
$filter-warning: darken($warning-color, 10%);
$filter-danger: darken($danger-color, 10%);

$black-footer-bg: #252422 !default;
$brown-font-color: #a49e9e !default;
$brown-font-color-hover: #f1eae0 !default;

$card-black-color: #333333 !default;
$card-muted-color: #ccc !default;

$card-background-blue: #b8d8d8 !default;
$card-font-blue: #506568 !default;
$card-subtitle-blue: #7a9e9f !default;

$card-background-green: #d5e5a3 !default;
$card-font-green: #60773d !default;
$card-subtitle-green: #92ac56 !default;

$card-background-yellow: #ffe28c !default;
$card-font-yellow: #b25825 !default;
$card-subtitle-yellow: #d88715 !default;

$card-background-brown: #d6c1ab !default;
$card-font-brown: #75442e !default;
$card-subtitle-brown: #a47e65 !default;

$card-background-purple: #baa9ba !default;
$card-font-purple: #3a283d !default;
$card-subtitle-purple: #5a283d !default;

$card-background-orange: #ff8f5e !default;
$card-font-orange: #772510 !default;
$card-subtitle-orange: #e95e37 !default;

$card-background-primary: #427c89 !default;
$card-border-color: #427c89 !default;

$color-red-error: rgb(185, 74, 72) !default;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;

$color-light: #f8f9fa;

$width-default: 220px !default;

$zindex-select-dropdown: 1060 !default;

$input-color-placeholder: #999 !default;

$input-height: 48px;

$btn-text-size: 0.7rem;
$btn-text-size-mobile: 0.875rem;
$btn-disabled-background-color: #d4d4d4 !default;
$btn-disabled-color: #788497;

$footer-link-case: uppercase;

$hello-bar-bg: #292323;

:root {
  --primary9: #dee2e6;

  --gris1: #{$gris1-color};
  --gris2: #{$gris2-color};
  --gris3: #{$gris3-color};
  --gris4: #{$gris4-color};
  --gris5: #{$gris5-color};
  --gris6: #{$gris6-color};
  --gris7: #{$gris7-color};
  --gris8: #{$gris8-color};
  --gris10: #{$gris10-color};

  --gold1: #{$gold1-color};
  --gold2: #{$gold2-color};
  --gold3: #{$gold3-color};
  --orange-color: #{$orange-color};
  --yellow-color: #{$yellow-color};
  --red1-color: #{$red1-color};

  --font-family-primary: #{$font-family-primary};
  --font-family-secondary: #{$font-family-secondary};

  --success-color: #{$success-color};
  --warning-color: #{$warning-color};
  --white-color: #{$white-color};
  --black-color: #{$primary-color};

  --black-hr: #{$black-hr};
  --black2-color: #{$black2-color};

  --step-color: #{$gold3-color};
  --step-text-color: #{$gris4-color};
  --step-disabled-color: #{$gris2-color};
  --step-disabled-border: #{$transparent-bg};
  --step-disabled-text: #{$gris3-color};
  --step-active: #{$gris2-color};
  --hello-bar-bg-color: #{$hello-bar-bg};
  --sidebar-header-color: #{$gris1-color};

  --filter-slider-color: #{$success-color};
  --filter-disabled-text-color: #{$muted-color};

  --filter-badge-background-color: #{$gold1-color};
  --filter-badge-color: #{$gris6-color};
  --filter-badge-border: #{$filter-badge-radius};
  --filter-badge-font: #{$font-family-primary};
  --filter-badge-font-size: #{$font-size-medium};
  --filter-badge-font-weight: #{$font-weight-semi};
  --filter-checkbox-background-color: #{$beige-color};
  --card-background-color: #{$primary-color};
  --border-color: #{$primary-color};
  --border-color-hover: #{$primary-color};

  --dark-brown-color: #{$dark-brown-color};

  --footer-bg-color: #{$success-color-2};
  --footer-text-color: #{$primary-gold-color};
  --footer-title-font: #{$font-family-primary};
  --footer-link-font: #{$font-family-primary};
  --footer-link-color: #{$color-light};
  --footer-botom-bar: #{$footer-bottom-bar-color};
  --footer-bottom-text-color: #{$white-color};
  --footer-title-line-height: #{$line-height-title-footer};
  --footer-link-line-height: #{$line-height-link-footer};
  --footer-border-color: #{$grayish-orange-color};
  --footer-link-font-size: #{$font-size-medium};
  --footer-link-text-transform: #{$footer-link-case};
  --footer-link-font-weight: #{$font-weight-bold};
  --footer-border-bottom-color: #{$dark-grayish-color};
  --footer-border: #{$white-color};

  --grey-color: #{$grey-color};
  --danger-color: #{$danger-color};

  --jumbotron-contacto-invitado: url('#{$urlPath}/img/sections/contacto.jpg');
  --jumbotron-contacto-invitado-mobile: url('#{$urlPath}/img/sections/contacto_movil.jpg');
  --jumbotron-consulta-pedido-invitado: url('#{$urlPath}/img/sections/seguimiento.jpg');
  --jumbotron-consulta-pedido-invitado-mobile: url('#{$urlPath}/img/sections/seguimiento-movil.jpg');
  --jumbotron-trabajo: url('#{$urlPath}/img/sections/trabaja.jpg');
  --jumbotron-trabajo-mobile: url('#{$urlPath}/img/sections/trabaja_movil.jpg');
  --jumbotron-banner_localizar-pedido: url('#{$urlPath}/img/sections/banner_localizar-pedido_desk.png');
  --jumbotron-banner_localizar-pedido-mobile: url('#{$urlPath}/img/sections/banner_localizar_pedido_mobile.png');
  --jumbotron-banner-contacto: url('#{$urlPath}/img/sections/banner_contacto_desk.png');
  --jumbotron-banner-contacto-mobile: url('#{$urlPath}/img/sections/banner_contacto_mobile.png');
  --jumbotron-banner-trabaja-con-nosotros: url('#{$urlPath}/img/sections/banner_trabajaTrabajanosotros_desk.png');
  --jumbotron-banner-trabaja-con-nosotros-mobile: url('#{$urlPath}/img/sections/banner_Trabajanosotros_mobile.png');

  --icon-favorite: url('#{$urlPath}/iconos/icon_favorite.svg');
  --icon-favorite-selected: url('#{$urlPath}/iconos/icon_favorite_selected.svg');
  --icon-chevron-right: url('#{$urlPath}/iconos/icon_chevron-right.svg');
  --icon-last-units: url('#{$urlPath}/iconos/icon_last-units.svg');
  --icon-exclamation: url('#{$urlPath}/iconos/icon_no-stock.svg');

  --pre-sale-primary-color: #{$pre-sale-color};
  --pre-sale-secondary-color: #{$pre-sale-1-color};
}
