@use '../assets/scss/paper-kit/variables';

.invalid-feedback {
  font-family: var(--font-family-primary);
  font-size: 0.625rem;
  color: var(--red1-color);
}

.primary-font-family {
  font-family: var(--font-family-primary);
}

.semibold-text {
  font-weight: 600 !important;
}

.bold-text {
  font-weight: 700 !important;
}

.thin-text {
  font-weight: 100 !important;
}

.light-text {
  font-weight: 300 !important;
}

.primary-color {
  color: $primary-color !important;
}

.secondary-color {
  color: $success-color !important;
}

.error-color {
  color: $warning-color !important;
}

.white-color {
  color: $white-color !important;
}

.muted-color {
  color: $muted-color !important;
}

.gris-1-color {
  color: $gris1-color !important;
}

.gris-2-color {
  color: $gris2-color !important;
}

.gris-3-color {
  color: $gris3-color !important;
}

.gris-4-color {
  color: $gris4-color !important;
}

.gris-5-color {
  color: $gris5-color !important;
}
.gris-6-color {
  color: $gris6-color !important;
}

.gold-1-color {
  color: $gold1-color !important;
}
.gold-2-color {
  color: $gold2-color !important;
}
.gold-3-color {
  color: $gold3-color !important;
}
.gris-9-color {
  color: $gris9-color !important;
}

.gris-10-color {
  color: var(--gris10) !important;
}

.bg-gris-10-color {
  background-color: var(--gris10) !important;
}

.pre-sale-primary-color {
  color: var(--pre-sale-primary-color) !important;
}
.pre-sale-secondary-color {
  color: var(--pre-sale-secondary-color) !important;
}

.bg-pre-sale-primary {
  background-color: var(--pre-sale-primary-color) !important;
}
.bg-pre-sale-secondary {
  background-color: var(--pre-sale-secondary-color) !important;
}

.orange-color {
  color: $orange-color !important;
}
.yellow-color {
  color: $yellow-color !important;
}

.form-control.no-border {
  border: none !important;
  outline: none !important;
}

.embed-responsive.embed-responsive-80::before {
  padding-top: 80%;
}

/* BACKGROUND */
.bg-success {
  background-color: $success-color !important;
}

.bg-white {
  background-color: $white-color !important;
}

.bg-gris-1 {
  background-color: var(--gris1);
}

.bg-gris3 {
  background-color: $gris3-color !important;
}

.bg-gris6 {
  background-color: $gris6-color !important;
}

.bg-gris7 {
  background-color: $gris7-color !important;
}

.bg-gold1 {
  background-color: $gold1-color !important;
}

/* TEXT DECORATION */

.btn-space-1 {
  line-height: 1 !important;
}

video {
  position: relative;
}

.text {
  &-underline {
    text-decoration: underline !important;
  }
}

.nav-up {
  @media screen and (min-width: 992px) {
    top: -183px;
  }
  top: -203px;
}

.bg-light-grayish-color {
  background-color: $light-grayish-color !important;
}

.rotate-180 {
  transform: rotate(180deg);
}
